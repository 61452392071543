import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/rani.mp3'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/aulia/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import post from '../params/post'
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#b99225'
let black = 'rgb(38,38,38)'

export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: null
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("12/12/2020").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })


    }, 1000);

  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
       if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "rani-toar", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }
  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
    let slide = ["	https://i.ibb.co/GVqkSm6/6B1C9008.jpg	",
      "	https://i.ibb.co/p2rF5JS/6B1C9231.jpg	",
      "	https://i.ibb.co/n74dKTV/6B1C9298.jpg	",
      "	https://i.ibb.co/3cwYGWR/6B1C9433.jpg	",
      "	https://i.ibb.co/3dHcrg3/6B1C9631.jpg	",
      "	https://i.ibb.co/44PFNRg/6B1C9680.jpg	",
      "	https://i.ibb.co/f9MPg08/6B1C9687.jpg	",
      "	https://i.ibb.co/fvGjFcW/6B1C9705.jpg	",
      "	https://i.ibb.co/PzPKyNh/6B1C9732.jpg	",
      "	https://i.ibb.co/h1gyk7b/6B1C9766.jpg	",
      "	https://i.ibb.co/yBbdxZc/6B1C9859.jpg	",
      "	https://i.ibb.co/N6FCZQ0/6B1C9892.jpg	",
      "	https://i.ibb.co/CnGY5Yb/6B1C9893.jpg	",
      "	https://i.ibb.co/J5ZKqp7/6B1C8576.jpg	",
      "	https://i.ibb.co/YZGd1Jk/6B1C8872.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let tamu = [
      { nama: "Daimon & Eby", sesi: 2 },
      { nama: "Davel", sesi: 2 },
      { nama: "Steven", sesi: 2 },
      { nama: "Indah & Andrew", sesi: 2 },
      { nama: "Jess & Chris", sesi: 2 },
      { nama: "Randy & Istri", sesi: 2 },
      { nama: "Gayuh & Agnes", sesi: 2 },
      { nama: "David & Sesillia", sesi: 2 },
      { nama: "Tia Esa Sasmita", sesi: 2 },
      { nama: "Stella & Victor", sesi: 2 },
      { nama: "Sanusi Raha", sesi: 2 },
      { nama: "Citra & Michael", sesi: 2 },
      { nama: "Cindy & Suami", sesi: 2 },
      { nama: "Nina & Suami", sesi: 2 },
      { nama: "Rico Kwandi", sesi: 2 },
      { nama: "Claudia ", sesi: 2 },
      { nama: "Milli Lumintang", sesi: 2 },
      { nama: "Bpk. Era & Istri", sesi: 2 },
      { nama: "Nita & Sandy", sesi: 2 },
      { nama: "Nena & Marvin", sesi: 2 },
      { nama: "Ribka & Fendy", sesi: 2 },
      { nama: "Octa & Tasya", sesi: 2 },
      { nama: "Linda Politon", sesi: 2 },
      { nama: "Ivan & Viona", sesi: 2 },
      { nama: "Natasya Dondokambey", sesi: 2 },
      { nama: "Virgin & Suami", sesi: 2 },
      { nama: "Reinard & istri", sesi: 2 },
      { nama: "Jero ", sesi: 2 },
      { nama: "Maggie Wuisan", sesi: 2 },
      { nama: "Marcella Mamengko & Husband", sesi: 2 },
      { nama: "Richard & Anggie", sesi: 2 },
      { nama: "Andhika Kapojos & Wife", sesi: 2 },
      { nama: "Rentsa Tambuwun & Wife", sesi: 2 },
      { nama: "Kel. Mentauruk - Moniaga", sesi: 2 },
      { nama: "Ryan Pangemanan & Wife", sesi: 2 },
      { nama: "Mamesah - Sumarauw", sesi: 2 },
      { nama: "Bpk. Teguh Samudera", sesi: 2 },
      { nama: "Kel. Malee Teppy", sesi: 2 },
      { nama: "Kel. Arie Andes. SH. MH.", sesi: 2 },
      { nama: "Bpk. Fredrick Runtuwene", sesi: 1 },
      { nama: "Kel. Lingkan D.E.L. Waney", sesi: 1 },
      { nama: "Kel. Frelly Jimmy Tommy Leong", sesi: 1 },
    ]
    let query = this.useQuery().get('u');
    let tamukhusus
    if (query) {
      tamukhusus = tamu.find(u => { return u.nama == query })
    }
    let pilihansesi
    if (tamukhusus) {
      pilihansesi = tamukhusus.sesi
      sesi=pilihansesi
    }

    return (
      <>
        <Helm
          title='Undanganku - Rani & Toar'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={pw("rani","rani.jpg")}
          url='https://undanganku.me/rani-toar'
        />

        <div id='gold5' style={{
          backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
          backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative' style={{
              backgroundImage: `url('${gambar('https://i.ibb.co/n1dnYRY/Modal.jpg', 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1080&h=720')}')`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/3SkwbHh/Logo.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                    <br /> {query ? `Kepada Yth : ${query}` : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}

                  className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0, color: 'white' }}>
                  Open Invitation
                  </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>

                    <p className="fs24 text-center cblack">
                      <span className="fs16">

                        We love because He first loved us.<br />
                     (1 John 4:19)

                    </span>
                    </p>
                  </Item>
                  <Item>
                    <Col xs={6} sm={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                      The Intimate Wedding of <br />
                            Rani & Toar
                              </p>
                  </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                            Rani
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/KXmcnGS/rani.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                            Maharani Teppi SE.
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>Daughter of: </b><br />
                            Mr Jeffry Hunter Piter Teppi<br />
                        &<br />
                        Mrs Jooke Katrien Magdalena Antow
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/Its.mrani') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                            Toar
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/NswgrhR/toar-2.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                            Jusuf Toar Hengki Lewu SH.
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>Son of : </b><br />
                            Mr Ronny Harry Welly Lewu
                        <br />
                        &<br />
                        Mrs Miranda Palilingan
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/toraattorney ') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                  <Item>
                    <p className="fs16">
                      Which will be held on:
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20">
                      <b>
                        Saturday  <span className="fs36">12</span> th of December 2020
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: cmain }}>
                      <b>Holy Matrimony </b><br />
                      <span className="cblack fs16">
                        10.00 - 13.00 WITA
                      </span><br />
                      <span className="cblack fs12">
                        <b>Barame Resto</b><br />
Grand Luley Hotel & resort Manado
                      </span>
                    </p>
                    <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                      \
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                      <b>Reception</b><br />
                      <span className="cblack fs16">
                        14.00 - 16.00 WITA
                      </span> <br />
                      <span className="cblack fs12">
                        <b>GRAND VILLA
</b><br />
Grand Luley Hotel & resort Manado
                      </span>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs16 pt-3">

                      <span >
                        <b>Grand Luley Hotel Resort Walewangko Villa Tongkaina,Manado</b><br />
Bunaken, Kota Manado, Sulawesi Utara, 95231


                    </span>
                    </p>

                  </Item>
                  <Item>
                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://www.google.co.id/maps/dir//grand+luley+manado/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3287992e5635e779:0xe06bd3c9ab8c00a?sa=X&hl=en-id&ved=2ahUKEwiSquzY9Z3tAhVFWH0KHdb1D6oQ9RcwFnoECBMQBA")
                      }}
                      className="p-2 mx-sm-2 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                    <Col
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=Nm83NWg5Nmg2aHZmcjJwbHJ2dmMxaDcxaDkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container id='sectiongold57'>
                  <div className='pt-3'>

                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>
                <Container className="text-center dinny mt-3">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: cmain
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                        </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                      </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>


                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>
                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4' style={{ fontSize: '16px' }}>
                              I will be faithful to you and honest with you;<br />
                                I will respect, trust, help and care for you; <br />
                                I will share my life with you; <br />
                                I will forgive you as we have been forgiven;<br />
                                and I will try with you better to understand ourselves,<br />
                                the world, and God;<br />
                                through the best and the worst of what is to come as <br />
                                long as we live.
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>

                <Container id='sectiongold58' >

                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: cmain
                          }}>
                            Send Your Wishes
                        </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label style={{ color: cmain }} for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label style={{ color: cmain }} for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>
                                  {!hadir ? false : (
                                    pilihansesi ? (
                                      <>  <Alert variant='dark col-12 mr-4 '>
                                        <p className="f-14">
                                          JADWAL KEDATANGAN
                                      </p>
                                        {
                                          pilihansesi == 1 ? (
                                            <div onClick={() => {
                                              this.setState({ sesi: 1 })
                                            }
                                            }
                                              className="pl-5">
                                              <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} disabled={pilihansesi != 1 ? true : false} />
                                              <label for="featured-3">

                                                Sesi 1 : 10.00 - 13.00 WITA</label>
                                            </div>
                                          ):false
                                      }
                                        {
                                          pilihansesi==2?(
                                            <div onClick={() => {
                                              this.setState({ sesi: 2 })
                                            }
                                            } className="pl-5">
                                              <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} disabled={pilihansesi != 2 ? true : false} />
                                              <label for="featured-4"
    
                                              >Sesi 2 : 14.00 - 16.00 WITA</label>
                                            </div>
                                          ):false
                                        }
                                        <p className="f-14">
                                          Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                      </p>
                                      </Alert>
                                      </>
                                    ) : false
                                  )}
                                </div>
                              </div>
                            </Item>

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{ fontSize: '16px' }}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div className='col-6 button btn rounded'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                <Foot ig={logoig} />
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

